<template>
  <div>
    <v-icon small class="mr-2" @click="showTask()"> mdi-eye </v-icon>

    <v-dialog
      v-model="dialogShowTask"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" style="flex: none">
          <v-btn icon dark @click="dialogShowTask = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Tarea: {{ taskData.taskID }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pt-2 bg-default">
          <v-row>
            <v-col cols="12">
              <TaskCertificate
                v-if="oMap"
                ref="TaskCertificate"
                :taskData="taskData"
                :oMap="oMap"
              ></TaskCertificate>
            </v-col>
          </v-row>
          <v-row class="font-size-120 pt-1 pb-4">
            <v-col cols="6">
              <div class="card card-custom card-stretch gutter-b">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Datos Generales</h3>
                  </div>
                </div>
                <div class="card-body p-5 pb-7">
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">Mandante: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ taskData.mandanteNombre }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">ID Campaña: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ taskData.campaignID }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">Campaña: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ taskData.campaignName }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">Tipo de Campaña: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      <span>{{ taskData.campaignType }}</span>
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">Fecha Inicio: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ taskData.initDate }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">Fecha Termino: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ taskData.finalDate }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">Tareas: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ taskData.tasks }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">Dias: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ taskData.days }}
                    </v-col>
                  </v-row>
                  <v-divider class="mt-5 mb-5"></v-divider>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">Jobber: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ taskData.jobberName }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">Fecha Asigna: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ taskData.assignmentDate }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">Fecha Gestión: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ taskData.managmentDate }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder"
                        >Fecha Transacción Gestión:
                      </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      {{ taskData.managmentTransactionDate }}
                    </v-col>
                  </v-row>
                  <v-row class="bb-light">
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">Estado: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      <span
                        :class="
                          taskData.estadoID == 1
                            ? 'text-danger'
                            : 'text-success'
                        "
                      >
                        {{ taskData.estado }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row
                    :class="
                      index != Object.keys(taskData.jobsFile).length - 1
                        ? 'bb-light'
                        : ''
                    "
                    v-for="(job, index) in taskData.jobsFile"
                    :key="job.key"
                  >
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">{{ job.name }}: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      <span>{{ job.value }}</span>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-5 mb-5"></v-divider>
                  <v-row
                    :class="
                      index != Object.keys(taskData.jobsConfig).length - 1
                        ? 'bb-light'
                        : ''
                    "
                    v-for="(job, index) in taskData.jobsConfig"
                    :key="job.key"
                  >
                    <v-col cols="6" class="pt-2 pb-2">
                      <span class="font-weight-bolder">{{ job.name }}: </span>
                    </v-col>
                    <v-col cols="6" class="pt-2 pb-2">
                      <span v-if="job.tipoID !== 10">{{ job.value }}</span>
                      <TaskImage
                        v-if="
                          job.tipoID === 10 &&
                          job.value != '' &&
                          job.value != true
                        "
                        :iCampaignID="taskData.campaignID"
                        :sFilename="job.value"
                      >
                      </TaskImage>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="card card-custom card-stretch gutter-b">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Mapa</h3>
                  </div>
                </div>
                <div class="card-body p-5">
                  <div>
                    <TaskMap
                      v-if="dialogShowTask"
                      ref="task_map"
                      :aJobs="aJobs"
                      :bCircle="false"
                      map="task_map"
                    />
                  </div>
                  <div class="mt-2 text-center" v-if="taskData.latitud_jobber">
                    Coordenadas de la realización de la tarea:
                    {{ taskData.latitud_jobber }} /
                    {{ taskData.longitud_jobber }}
                  </div>
                  <div
                    class="mt-2 text-center"
                    v-if="
                      taskData.distancia_tarea_jobber &&
                      taskData.distancia_tarea_jobber > 100
                    "
                  >
                    Alerta: Tarea fue realizada a una distancia mayor a 100
                    metros de las coordenadas de la dirección. ({{
                      taskData.distancia_tarea_jobber
                    }}
                    metros)
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="card card-custom card-stretch gutter-b">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Arbol</h3>
                  </div>
                </div>
                <div class="card-body p-5">
                  <v-data-table
                    :headers="taskData.headersTreeRows"
                    :items="taskData.treeRows"
                    disable-pagination
                    hide-default-footer
                    class="table-striped elevation-1"
                    id="tree_table"
                  >
                    <template v-slot:[`item.descripcion`]="{ item }">
                      {{ item.descripcion }}
                      <i
                        v-if="item.seleccionado"
                        class="fa fa-check text-success"
                      ></i>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import KTUtil from "@/assets/js/components/util";
import { _ } from "vue-underscore";
import { GET_ALL_TASK_DATA } from "@/core/services/store/task.module";
import { GET_CAMPAIGN_TREE_DATA_EXCEL } from "@/core/services/store/campaign.module";
import TaskCertificate from "./TaskCertificate.vue";
import TaskImage from "./TaskImage.vue";
import TaskMap from "./TaskMap.vue";
export default {
  name: "TaskDetail",
  components: {
    TaskCertificate,
    TaskImage,
    TaskMap,
  },
  props: {
    taskID: Number,
  },
  data() {
    return {
      taskData: {
        taskID: null,
        mandanteNombre: "",
        campaignID: null,
        campaingName: "",
        jobberName: "",
        estado: "",
        initDate: "",
        finalDate: "",
        tasks: "",
        days: "",
        attributes: [],
        jobsFile: [],
        jobsConfig: [],
        headersTreeRows: [
          {
            text: "Órden",
            value: "ordenCampo",
            align: "center",
            sortable: false,
          },
          { text: "Pregunta - campo", value: "pregunta", sortable: false },
          { text: "Tipo campo", value: "tipoCampo", sortable: false },
          {
            text: "Órden",
            value: "ordenOpcion",
            align: "center",
            sortable: false,
          },
          { text: "Respuesta", value: "descripcion", sortable: false },
          {
            text: "Próximo campo",
            value: "ordenProximoCampo",
            align: "center",
            sortable: false,
          },
          {
            text: "Descripción pregunta",
            value: "proximoCampo",
            sortable: false,
          },
        ],
        treeRows: [],
      },
      oMap: null,
      aJobs: [],
      dialogShowTask: false,
    };
  },
  methods: {
    async showTask() {
      const oVue = this;
      oVue.dialogShowTask = false;
      const aAllTaskDataResponse = await oVue.getAllTaskData(oVue.taskID);
      if (aAllTaskDataResponse.result) {
        const aGeneral = aAllTaskDataResponse.data.general;
        const aAttributes = (oVue.taskData.attributes =
          typeof aGeneral.atributos !== "object"
            ? JSON.parse(aGeneral.atributos)
            : aGeneral.atributos);
        const aFields = aAllTaskDataResponse.data.fields;
        const aFieldsOptions = aAllTaskDataResponse.data.options;
        const aJobs =
          typeof aAllTaskDataResponse.data.jobs !== "object"
            ? JSON.parse(aAllTaskDataResponse.data.jobs)
            : aAllTaskDataResponse.data.jobs;

        oVue.taskData.taskID = aGeneral.tareaID;
        oVue.taskData.mandanteNombre = aGeneral.mandanteNombre;
        oVue.taskData.campaignID = aGeneral.campanaID;
        oVue.taskData.campaignName = aGeneral.campanaNombre;
        oVue.taskData.jobberName = aGeneral.jobberNombre
          ? aGeneral.jobberNombre
          : "";
        oVue.taskData.assignmentDate = aGeneral.fechaAsigna
          ? aGeneral.fechaAsigna
          : "";
        oVue.taskData.managmentDate = aGeneral.fechaGestion
          ? aGeneral.fechaGestion
          : "";
        oVue.taskData.managmentTransactionDate =
          aGeneral.fechaTransaccionGestion
            ? aGeneral.fechaTransaccionGestion
            : "";
        oVue.taskData.estado = aGeneral.estado;
        oVue.taskData.initDate = aGeneral.fechaInicio;
        oVue.taskData.finalDate = aGeneral.fechaTermino;
        oVue.taskData.tasks = aGeneral.tareas;
        oVue.taskData.days = aGeneral.dias;
        oVue.taskData.campaignDescription = aGeneral.descripcionCampana;
        oVue.taskData.campaignType = aGeneral.tipoCampana;
        oVue.taskData.campaignStatus = aGeneral.estadoCampana;
        oVue.taskData.latitud = aGeneral.latitud;
        oVue.taskData.longitud = aGeneral.longitud;
        oVue.taskData.latitud_jobber = aGeneral.latitud_jobber;
        oVue.taskData.longitud_jobber = aGeneral.longitud_jobber;
        oVue.taskData.distancia = null;
        oVue.taskData.estadoID = aGeneral.estadoTareaID;

        let aFinalFields = [];

        for (let i = 0; i <= aFields.length - 1; i++) {
          const aField = aFields[i];

          let aFieldOptions = [];

          if (aField.hasOptions) {
            const aOptions = _.filter(aFieldsOptions, {
              idCampo: aField.idCampo,
            });

            aFieldOptions = _.map(aOptions, function (aOption) {
              return {
                value: aOption.opcion,
                enlaceCampo: aOption.enlaceCampo,
                key: aOption.idOpcion,
              };
            });
          }

          let aNewField = {
            titulo: aField.titulo,
            tipo: aField.tipo,
            tipoID: aField.tipoID,
            opciones: aFieldOptions,
            enlaceCampo: aField.enlaceCampo === null ? "" : aField.enlaceCampo,
            key: aField.idCampo,
            origen: aField.origen,
          };
          aFinalFields.push(aNewField);
        }

        oVue.taskData.jobsFile = _.map(
          _.filter(aFinalFields, { origen: "FILE" }),
          function (aField) {
            let aJob = aJobs["campo_" + aField.key];
            let value = aJob ? aJob.value : "";
            return {
              name: aField.titulo,
              value,
              tipoID: aField.tipoID,
              origen: aField.origen,
            };
          }
        );
        oVue.taskData.jobsConfig = _.map(
          _.filter(aFinalFields, { origen: "CONFIG" }),
          function (aField) {
            let aJob = aJobs["campo_" + aField.key];
            let value = aJob ? aJob.value : "";
            return {
              name: aField.titulo,
              value,
              tipoID: aField.tipoID,
              origen: aField.origen,
            };
          }
        );

        let jobDireccionIndex = oVue.taskData.jobsFile.findIndex((j) =>
          j.name.toLowerCase().includes("direccion")
        );
        if (jobDireccionIndex === -1)
          jobDireccionIndex = oVue.taskData.jobsFile.length;
        const jobLatLngIndex = oVue.taskData.jobsFile.findIndex((j) =>
          j.name.toLowerCase().includes("latitud")
        );
        if (jobLatLngIndex === -1) {
          const aFieldLatLng = {
            name: "Latitud / Longitud",
            value: `${oVue.taskData.latitud} / ${oVue.taskData.longitud}`,
          };
          oVue.taskData.jobsFile.splice(jobDireccionIndex + 1, 0, aFieldLatLng);
        }

        const aAllTreeDataResponse = await oVue.getCampaignTreeDataExcel(
          oVue.taskData.campaignID
        );
        let iOrdenCampo = 0;
        let iOrdenOpcion = 0;
        oVue.taskData.treeRows = _.map(aAllTreeDataResponse, function (aTree) {
          if (aTree.ordenCampo == iOrdenCampo) {
            if (aTree.tieneOpciones == 0) {
              iOrdenOpcion = 1;
            } else {
              iOrdenOpcion++;
            }
            aTree.ordenCampo = "";
            aTree.pregunta = "";
            aTree.tipoCampo = "";
          } else {
            iOrdenCampo = aTree.ordenCampo;
            iOrdenOpcion = 1;
          }
          const aAttribute = aAttributes["campo_" + aTree.idCampo];
          if (aAttribute) {
            if (aTree.tipoCampoID == 3) {
              aTree.seleccionado = aAttribute.value === aTree.descripcion;
            } else {
              aTree.seleccionado =
                aAttribute.value != "" && aAttribute.value != true;
            }
            aTree.respuesta = aAttribute.value;
          } else {
            aTree.seleccionado = false;
            aTree.respuesta = "";
          }
          aTree.ordenOpcion = iOrdenOpcion;
          return aTree;
        });
        oVue.dialogShowTask = true;
        setTimeout(() => {
          oVue.initMap(aGeneral.latitud, aGeneral.longitud);
          oVue.taskData.distancia_tarea_jobber =
            aGeneral.distancia_tarea_jobber;
        }, 500);
      }
    },
    async getAllTaskData(iTaskID) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllTaskDataResponse = await oVue.$store.dispatch(
        GET_ALL_TASK_DATA,
        { task: iTaskID }
      );
      KTUtil.removeLoading();
      return aAllTaskDataResponse;
    },
    initMap(lat, lng) {
      let oVue = this;
      const taskData = oVue.taskData;
      let aJobs = [
        {
          latitud: lat,
          longitud: lng,
          idEstado: taskData.estadoID,
          type: "task",
        },
      ];

      if (oVue.taskData.latitud_jobber) {
        aJobs.push({
          latitud: taskData.latitud_jobber,
          longitud: taskData.longitud_jobber,
          idEstado: taskData.estadoID,
          type: "jobber",
        });
      }

      oVue.aJobs = aJobs;
      oVue.$refs.task_map.initJobsMap();
      setTimeout(() => {
        oVue.oMap = KTUtil.getById("task_map");
      }, 1000);
    },
    async getCampaignTreeDataExcel(iCampaignID) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllCampaignTreeDataExcelResponse = await oVue.$store.dispatch(
        GET_CAMPAIGN_TREE_DATA_EXCEL,
        { campaign: iCampaignID }
      );
      KTUtil.removeLoading();

      return aAllCampaignTreeDataExcelResponse;
    },
  },
};
</script>
