<template>
  <div data-app>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pt-3 pb-0">
        <div class="row">
          <div
            class="col-md-3"
            v-if="Number(currentUserPersonalInfo.perfil_id) === 1"
          >
            <v-autocomplete
              v-model="mandanteID"
              :items="mandantes"
              item-value="mandanteID"
              item-text="mandanteNombre"
              label="Mandante"
              dense
              @change="getGroups"
            ></v-autocomplete>
          </div>
        </div>
        <v-data-table
          :headers="headerGroups"
          :items="groups"
          :sort-by="['fechaCreacion']"
          :sort-desc="true"
          :search="groupSearch"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Listado de Grupos</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="groupSearch"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on:click="showNewGroupDialog()"
                v-if="Number(currentUserPersonalInfo.perfil_id) === 1"
              >
                Nuevo Grupo
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="() => {}"> mdi-eye </v-icon>
            <v-icon small class="mr-2" @click="editGroup(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="showTasks(item)">
              mdi-chart-bar-stacked
            </v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="newGroupDialog" persistent max-width="50%">
          <v-card tile>
            <v-card-title>
              <span class="headline">Agregar grupo</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="group.nombre"
                      label="Nombre del grupo"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="group.descripcion"
                      label="Descripción del grupo"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text v-on:click="cleanGroupDialog()">
                Cerrar
              </v-btn>
              <v-btn color="blue darken-1" text v-on:click="saveGroup()">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="tasks.dialog"
          fullscreen
          hide-overlay
          persistent
          transition="dialog-bottom-transition"
          scrollable
        >
          <v-card tile>
            <v-toolbar flat dark color="primary" style="flex: none">
              <v-btn icon dark @click="tasks.dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Grupo de campañas</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="pt-2 bg-default">
              <v-row class="font-size-120 pt-1 pb-4">
                <v-col cols="12">
                  <div class="card">
                    <div class="card-body pt-3 pb-0">
                      <v-row>
                        <v-col cols="3">
                          <v-text-field
                            v-model="fechaAsignaText"
                            @click="$refs['modalFechaAsigna'].show()"
                            label="Fecha de Asignación"
                            readonly
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="fechaRealizaText"
                            @click="$refs['modalFechaRealiza'].show()"
                            label="Fecha de Realización"
                            readonly
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <div class="row">
                        <!-- <div class="col-md-3" v-for="(filter) in filters" :key="filter.key">
                          <v-text-field
                            v-model="filter.input"
                            @input="showTasks"
                            :label="filter.text"
                            dense
                          ></v-text-field>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div class="card card-custom card-stretch gutter-b">
                    <div class="card-body p-5 pb-7">
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                            :headers="tasks.tasksHeader"
                            :items="tasks.tasks"
                            class="table-striped elevation-1"
                            :hide-default-footer="true"
                            :items-per-page="pageSize"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            @update:sort-by="sortTable"
                            @update:sort-desc="sortTable"
                          >
                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-toolbar-title>Listado</v-toolbar-title>
                                <v-divider
                                  class="mx-4"
                                  inset
                                  vertical
                                ></v-divider>
                                <v-spacer></v-spacer>
                                <v-text-field
                                  v-model="taskSearch"
                                  append-icon="search"
                                  label="Buscar"
                                  single-line
                                  hide-details
                                ></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-on:click="
                                    downloadExcelFile(
                                      'campos',
                                      'jobs_table',
                                      tasks.tasksHeader.length
                                    )
                                  "
                                >
                                  Exportar
                                </v-btn>
                              </v-toolbar>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <TaskDetail :taskID="item.tareaID"></TaskDetail>
                            </template>
                          </v-data-table>
                          <v-data-table
                            :headers="headerExcel"
                            :items="bodyExcel"
                            class="elevation-1 d-none"
                            id="jobs_table"
                            disable-pagination
                            hide-default-footer
                          >
                          </v-data-table>
                          <v-col cols="12" sm="12">
                            <v-row>
                              <v-col cols="4" sm="2">
                                <v-select
                                  v-model="pageSize"
                                  :items="pageSizes"
                                  label="Filas por página"
                                  @change="handlePageSizeChange"
                                ></v-select>
                              </v-col>
                              <v-col cols="8" sm="10">
                                <v-pagination
                                  v-model="page"
                                  :length="totalPages"
                                  @input="handlePageChange"
                                  total-visible="7"
                                  next-icon="mdi-menu-right"
                                  prev-icon="mdi-menu-left"
                                ></v-pagination>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <b-modal
          ref="modalFechaAsigna"
          :title="modalFechaAsignaTitle"
          size="xl"
          hide-footer
        >
          <form class="form">
            <div class="pb-5">
              <v-row>
                <v-col cols="12">
                  <v-date-picker
                    v-model="fechaAsigna"
                    range
                    dark
                    full-width
                    selected-items-text=""
                  ></v-date-picker>
                </v-col>
              </v-row>
              <div class="d-flex justify-content-between pt-10 float-right">
                <v-btn
                  @click="clearFilter('fechaAsigna')"
                  dark
                  class="font-weight-bold text-uppercase px-9 py-4 mr-1"
                >
                  Limpiar
                </v-btn>
                <v-btn
                  @click="
                    showTasks();
                    $refs['modalFechaAsigna'].hide();
                  "
                  color="primary"
                  dark
                  class="font-weight-bold text-uppercase px-9 py-4"
                >
                  Guardar
                </v-btn>
              </div>
            </div>
          </form>
        </b-modal>
        <b-modal
          ref="modalFechaRealiza"
          :title="modalFechaRealizaTitle"
          size="xl"
          hide-footer
        >
          <form class="form">
            <div class="pb-5">
              <v-row>
                <v-col cols="12">
                  <v-date-picker
                    v-model="fechaRealiza"
                    range
                    dark
                    full-width
                    selected-items-text=""
                  ></v-date-picker>
                </v-col>
              </v-row>
              <div class="d-flex justify-content-between pt-10 float-right">
                <v-btn
                  @click="clearFilter('fechaRealiza')"
                  dark
                  class="font-weight-bold text-uppercase px-9 py-4 mr-1"
                >
                  Limpiar
                </v-btn>
                <v-btn
                  @click="
                    showTasks();
                    $refs['modalFechaRealiza'].hide();
                  "
                  color="primary"
                  dark
                  class="font-weight-bold text-uppercase px-9 py-4"
                >
                  Guardar
                </v-btn>
              </div>
            </div>
          </form>
        </b-modal>
        <v-dialog
          v-model="editGroupDialog"
          fullscreen
          hide-overlay
          persistent
          transition="dialog-bottom-transition"
          scrollable
        >
          <v-card tile>
            <v-toolbar flat dark color="primary" style="flex: none">
              <v-btn
                icon
                dark
                @click="
                  cleanGroupDialog();
                  editGroupDialog = false;
                "
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Editar grupo</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="pt-2 bg-default">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div class="card card-custom card-stretch mb-2">
                      <div class="card-body p-5 pb-7">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="group.nombre"
                              label="Nombre del grupo"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="group.descripcion"
                              label="Descripción del grupo"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <div
                          class="d-flex justify-content-between pt-10 float-right"
                        >
                          <v-btn
                            @click="saveGroup()"
                            color="primary"
                            dark
                            class="font-weight-bold text-uppercase px-9 py-4"
                          >
                            Guardar
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="card card-custom card-stretch mb-2">
                      <div class="card-header">
                        <div class="card-title">
                          <h3 class="card-label">
                            Campañas:
                            {{ group.campaigns ? group.campaigns.length : 0 }}
                          </h3>
                        </div>
                      </div>
                      <div class="card-body p-5 pb-7">
                        <v-row>
                          <v-col cols="8">
                            <v-autocomplete
                              v-model="campaign"
                              :items="campaignsSelect"
                              item-key="campaignID"
                              item-text="nameWithId"
                              return-object
                              label="Campaña"
                              autocomplete="new-password"
                              dense
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="4">
                            <v-btn
                              @click="addCampaignGroup()"
                              color="primary"
                              dark
                              class="font-weight-bold text-uppercase px-9 py-4 float-right"
                            >
                              Agregar
                            </v-btn>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="searchCampaign"
                              append-icon="search"
                              label="Buscar"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                              :headers="headerCampaigns"
                              :items="group.campaigns"
                              :search="searchCampaign"
                              disable-pagination
                              hide-default-footer
                              class="table-striped elevation-1"
                              id="campaign_table"
                            >
                              <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                  small
                                  class="mr-2"
                                  @click="deleteCampaignGroup(item)"
                                >
                                  mdi-delete
                                </v-icon>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { _ } from "vue-underscore";
import XLSX from "sheetjs-style";
//import { uuid } from "vue-uuid";
import moment from "moment";
import "moment/locale/es";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import TaskDetail from "../components/task/TaskDetail.vue";
import {
  GET_CAMPAIGNS_MANDANTE,
  GET_GROUPS,
  NEW_GROUP,
  UPDATE_GROUP,
  GET_ALL_CAMPAIGN_DATA,
  ADD_CAMPAIGN_GROUP,
  DELETE_CAMPAIGN_GROUP,
  GET_ALL_CAMPAIGN_DATA_BY_SOME_CAMPAIGNS,
} from "@/core/services/store/campaign.module";
import { GET_MANDANTES } from "@/core/services/store/mandante.module";
import { aOrden } from "../../helpers/nuevaMasVida";
export default {
  name: "campaignsGroups",
  components: {
    TaskDetail,
  },
  data() {
    return {
      mandanteID: null,
      mandantes: [],
      groups: [],
      aCampaigns: [],
      groupSearch: "",
      headerGroups: [
        {
          text: "ID",
          align: "center",
          value: "uniqueID",
          width: "5em",
          fixed: true,
        },
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Descripcion",
          value: "descripcion",
        },
        {
          text: "Fecha",
          value: "fechaHora",
          fixed: true,
        },
        {
          text: "",
          value: "actions",
          fixed: true,
          sortable: false,
        },
      ],
      newGroupDialog: false,
      group: {
        nombre: "",
        descripcion: "",
      },
      tasks: {
        fields: [],
        tasksHeader: [],
        tasks: [],
        dialog: false,
      },
      taskSearch: "",
      campaigns: [],
      campaign: null,
      campaignsSelect: [],
      filters: [],
      tasksFiltered: [],
      fechaAsigna: [],
      fechaRealiza: [],
      modalFechaAsignaTitle: "Fecha de Asignación",
      editGroupDialog: false,
      headerCampaigns: [
        {
          text: "ID",
          value: "campaignID",
        },
        {
          text: "Campaña",
          value: "nombre",
        },
        {
          text: "",
          value: "actions",
          fixed: true,
          sortable: false,
        },
      ],
      searchCampaign: null,
      campaignsID: [],
      page: 1,
      totalPages: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
      sortBy: ["tareaID"],
      sortDesc: true,
      awaitingSearch: false,
      headerExcel: [],
      bodyExcel: [],
    };
  },
  methods: {
    getMandantes() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.mandantes = [];
      oVue.$store.dispatch(GET_MANDANTES).then((mandantes) => {
        oVue.mandantes = mandantes;
        KTUtil.removeLoading();
      });
    },
    getCampaigns() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.campaigns = [];
      oVue.$store
        .dispatch(GET_CAMPAIGNS_MANDANTE, oVue.mandanteID)
        .then((campaigns) => {
          oVue.campaigns = campaigns.map((c) => {
            return {
              ...c,
              nameWithId: `${c.campaignID} - ${c.nombre}`,
            };
          });
          oVue.campaigns = oVue.campaigns.filter(
            (c) =>
              !oVue.group.campaigns.some(
                (campaignGroup) => campaignGroup.campaignID === c.campaignID
              )
          );
          oVue.campaignsSelect = oVue.campaigns.filter((c) =>
            oVue.isActiveCampaign(c)
          );
          KTUtil.removeLoading();
        });
    },
    getGroups() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.groups = [];
      oVue.$store
        .dispatch(GET_GROUPS, { mandante: Number(oVue.mandanteID) })
        .then((groups) => {
          oVue.groups = groups;
          KTUtil.removeLoading();
        });
    },
    showNewGroupDialog() {
      let oVue = this;
      oVue.group = {};
      if (oVue.mandanteID !== "null") {
        oVue.newGroupDialog = true;
      } else {
        Swal.fire({
          title: "¡Alerta!",
          text: "Debe seleccionar un mandante para crear una campaña",
          icon: "warning",
        });
      }
    },
    cleanGroupDialog() {
      const oVue = this;
      oVue.getGroups();
      oVue.newGroupDialog = false;
      oVue.editGroupDialog = false;
    },
    async saveGroup() {
      const oVue = this;

      const sNombre = oVue.group.nombre;
      const sDescripcion = oVue.group.descripcion;

      if (sNombre !== "") {
        if (sDescripcion !== "") {
          let aGroupResponse = null;
          if (oVue.group.uniqueID) {
            aGroupResponse = await oVue.updateGroup();
          } else {
            aGroupResponse = await oVue.newGroup();
          }
          if (aGroupResponse.result) {
            Swal.fire({
              title: "¡Enhorabuena!",
              text: "El grupo ha sido guardado",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            oVue.cleanGroupDialog();
          } else {
            Swal.fire({
              title: "¡Alerta!",
              text:
                "Hubo un error al guardar el grupo. - " +
                aGroupResponse.message,
              icon: "warning",
            });
          }
        } else {
          Swal.fire({
            title: "¡Alerta!",
            text: "Debe indicar el nombre del grupo",
            icon: "warning",
          });
        }
      } else {
        Swal.fire({
          title: "¡Alerta!",
          text: "Debe indicar la descripción del grupo",
          icon: "warning",
        });
      }
    },
    async newGroup() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.groups = [];
      const newGroupResponse = await oVue.$store.dispatch(NEW_GROUP, {
        mandante: Number(oVue.mandanteID),
        nombre: oVue.group.nombre,
        descripcion: oVue.group.descripcion,
      });
      KTUtil.removeLoading();
      return newGroupResponse;
    },
    async getAllCampaignData(iCampaignID) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllCampaignDataResponse = await oVue.$store.dispatch(
        GET_ALL_CAMPAIGN_DATA,
        { campaign: iCampaignID }
      );
      KTUtil.removeLoading();

      return aAllCampaignDataResponse;
    },
    async getAllCampaignDataBySomeCampaigns(aCampaigns, report) {
      KTUtil.setLoading();
      let oVue = this;

      const sortBy =
        oVue.sortBy && oVue.sortBy.length > 0 ? oVue.sortBy[0] : null;
      const sortDesc =
        oVue.sortDesc && oVue.sortDesc.length > 0 ? oVue.sortDesc[0] : false;

      const state = {
        page: oVue.page,
        items_per_page: oVue.pageSize,
        sort: sortBy,
        order: sortDesc ? "desc" : "asc",
        search: oVue.taskSearch,
        report,
      };

      const fechaAsigna = oVue.fechaAsigna[0] && oVue.fechaAsigna[0];

      const fechaAsignacion = {
        from: fechaAsigna,
        to: oVue.fechaAsigna[1] ? oVue.fechaAsigna[1] : fechaAsigna,
      };

      const fechaRealiza = oVue.fechaRealiza[0] && oVue.fechaRealiza[0];

      const fechaGestion = {
        from: fechaRealiza,
        to: oVue.fechaRealiza[1] ? oVue.fechaRealiza[1] : fechaRealiza,
      };

      const aData = {
        aCampaigns,
        fechaAsignacion,
        fechaGestion,
        state,
      };

      const aAllCampaignDataResponse = await oVue.$store.dispatch(
        GET_ALL_CAMPAIGN_DATA_BY_SOME_CAMPAIGNS,
        aData
      );
      KTUtil.removeLoading();

      return aAllCampaignDataResponse;
    },

    async getTasks(report = 0) {
      const oVue = this;

      const aCampaigns = oVue.campaigns;
      let aAllCampaignDataResponse;

      if (aCampaigns.length > 0) {
        /* if(aCampaigns[0].tipoID === 6){
          oVue.showTasksMasVida(aCampaigns);
          return;
        } */

        let aCampaignsArray = [];
        const aLoopCampaigns = await aCampaigns.map(async (aCampaign) => {
          const { campaignID: iCampaignID } = aCampaign;
          aCampaignsArray.push(iCampaignID);
        });
        await Promise.all(aLoopCampaigns);

        aAllCampaignDataResponse = await oVue.getAllCampaignDataBySomeCampaigns(
          aCampaignsArray,
          report
        );
      }
      return aAllCampaignDataResponse;
    },
    async showTasks(aGroup) {
      const oVue = this;

      oVue.tasks.tasksHeader = [];
      oVue.tasks.tasks = [];
      if (aGroup) {
        oVue.group = aGroup;
        oVue.campaigns = aGroup.campaigns;
        oVue.fechaAsigna = [];
        oVue.fechaRealiza = [];
        oVue.taskSearch = "";
        oVue.page = 1;
      }
      oVue.tasks.dialog = true;

      const aAllCampaignDataResponse = await oVue.getTasks();
      if (!aAllCampaignDataResponse.message) {
        const {
          data: aAllCampaignData,
          payload: { pagination },
        } = aAllCampaignDataResponse;

        if (!Array.isArray(aAllCampaignData)) {
          oVue.tasks.tasksHeader = [
            {
              text: "",
              value: "actions",
              sortable: false,
              fixed: true,
            },
            ...aAllCampaignData.header.filter((aHeader) => aHeader.show),
          ];
          oVue.tasks.tasks = aAllCampaignData.body;
        } else {
          oVue.tasks.tasks = [];
        }
        oVue.totalPages = pagination.last_page;
      }

      oVue.tasksFiltered = [...oVue.tasks.tasks];
    },
    async getTasksReport() {
      const oVue = this;
      const aAllCampaignDataResponse = await oVue.getTasks(1);
      if (!aAllCampaignDataResponse.message) {
        const { data: aAllCampaignData } = aAllCampaignDataResponse;
        oVue.headerExcel = aAllCampaignData.header.filter(
          (aHeader) => aHeader.show
        );
        oVue.bodyExcel = aAllCampaignData.body;
      }
    },
    async downloadExcelFile(filename, id, cols) {
      let oVue = this;

      await oVue.getTasksReport();
      const wb = (XLSX.WorkBook = XLSX.utils.book_new());

      //Datos personalizados

      const aCampaigns = oVue.campaigns;
      if (aCampaigns.length > 0) {
        if (aCampaigns[0].tipoID === 6) {
          wb.SheetNames.push("NuevaMasVida");
          wb.Sheets["NuevaMasVida"] = this.downloadExcelFileNuevaMasVida();
        } else {
          const elt = KTUtil.getById(id);
          const table = elt.querySelector("table");
          const ws = (XLSX.WorkSheet = XLSX.utils.table_to_sheet(table, {
            raw: true,
          }));
          const row = 0;
          for (let col = 0; col <= cols; col++) {
            const ref = XLSX.utils.encode_cell({ r: row, c: col });
            if (ws[ref]) {
              ws[ref].s = {
                font: {
                  bold: true,
                },
              };
            }
          }
          XLSX.utils.book_append_sheet(wb, ws, filename);
        }
        return XLSX.writeFile(wb, `${filename}.xlsx`);
      }
    },
    async showTasksMasVida(aCampaigns) {
      const oVue = this;
      oVue.campaigns = [];
      oVue.tasks.tasksHeader = [
        {
          text: "ID Tarea",
          value: "tareaID",
          origen: "CONFIG",
          search: true,
          input: "",
        },
        {
          text: "Periodo",
          value: "periodo",
          origen: "MANUAL",
          titulo: "Periodo",
          campos: [],
          search: true,
          input: "",
        },
        // {
        //   text: "Año",
        //   value: "year",
        //   origen: "MANUAL",
        //   titulo: "Año",
        //   campos: []
        // },
        // {
        //   text: "Mes",
        //   value: "month",
        //   origen: "MANUAL",
        //   titulo: "Mes",
        //   campos: []
        // },
        {
          text: "Estado",
          value: "estado",
          origen: "CONFIG",
          search: true,
          input: "",
        },
        {
          text: "Folio Licencia",
          value: "folioLicencia",
          origen: "MANUAL",
          titulo: "Folio licencia",
          campos: [],
          search: true,
          input: "",
        },
        {
          text: "Afiliado",
          value: "afiliado",
          origen: "MANUAL",
          titulo: "Nombre afiliado",
          campos: [],
          search: true,
          input: "",
        },
        {
          text: "Comuna Reposo",
          value: "comunaReposo",
          origen: "MANUAL",
          titulo: "comuna reposo",
          campos: [],
          search: true,
          input: "",
        },
        {
          text: "Fecha de Asignacion",
          value: "fechaAsigna",
          origen: "CONFIG",
        },
        {
          text: "Fecha Emisión",
          value: "fechaEmision",
          origen: "MANUAL",
          titulo: "Fecha emisión",
          campos: [],
        },
        {
          text: "¿Es por un accidente?",
          value: "accidente",
          origen: "MANUAL",
          titulo: "Declaración_accidente ",
          campos: [],
          search: true,
          input: "",
        },
        {
          text: "Resultado",
          value: "resultado",
          origen: "MANUAL",
          titulo: "Resultado de la visita",
          campos: [],
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          origen: "CONFIG",
        },
      ];

      oVue.filters = oVue.tasks.tasksHeader.filter((t) => t.search);

      const aLoopCampaigns = aCampaigns.map(async (aCampaign) => {
        const { campaignID: iCampaignID } = aCampaign;

        const aAllCampaignDataResponse = await oVue.getAllCampaignData(
          iCampaignID
        );

        if (aAllCampaignDataResponse.result) {
          oVue.campaigns.push(aAllCampaignDataResponse.data);
          const aFields = aAllCampaignDataResponse.data.fields;

          for (let i = 0; i <= aFields.length - 1; i++) {
            const aField = aFields[i];
            const headerIndex = oVue.tasks.tasksHeader.findIndex(
              (t) => t.titulo === aField.titulo
            );
            if (headerIndex !== -1) {
              oVue.tasks.tasksHeader[headerIndex].campos.push(aField.idCampo);
            }
          }
        }
      });

      await Promise.all(aLoopCampaigns);

      oVue.campaigns.forEach((aCampaign) => {
        const aGeneral = aCampaign.general;
        const aJobs = aCampaign.jobs;
        const aTasks = _.map(aJobs, function (aJob) {
          let aToReturn = aJob;
          const aAttributes =
            typeof aJob.attributes !== "object"
              ? JSON.parse(aJob.attributes)
              : aJob.attributes;

          for (let sIndex in aAttributes) {
            const aAttribute = aAttributes[sIndex];
            const iKeyID = Number(sIndex.replace("campo_", ""));

            const headerTask = oVue.tasks.tasksHeader.find((t) =>
              t.campos?.includes(iKeyID)
            );
            if (headerTask) {
              let sCampo = aAttribute.value;
              if (oVue.isISODateValid(sCampo))
                sCampo = moment(sCampo).format("DD-MM-YYYY");
              aToReturn[headerTask.value] = sCampo;
            }
          }

          aToReturn.tareaID = aJob.idJob;
          aToReturn.mandanteNombre = aGeneral.mandante;
          aToReturn.campanaID = aGeneral.campaignID;
          aToReturn.campanaNombre = aGeneral.nombre;
          aToReturn.jobberNombre = aJob.user;
          aToReturn.fechaAsigna = moment(
            aJob.assignmentDate,
            "DD-MM-YYYY HH:mm:ss"
          ).format("DD-MM-YYYY");
          aToReturn.fechaAsignaOriginal = moment(
            aJob.assignmentDate,
            "DD-MM-YYYY HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
          aToReturn.fechaGestion = aJob.managmentDate;
          aToReturn.estado = aJob.status;
          return aToReturn;
        });

        oVue.tasks.tasks.push(...aTasks);
      });
      oVue.tasksFiltered = [...oVue.tasks.tasks];
    },
    downloadExcelFileNuevaMasVida() {
      const oVue = this;
      let aExcelHeader = [];
      const headerExcel = oVue.headerExcel;
      aOrden.forEach((aOrden) => {
        let sHeader = "";
        if (aOrden.isCol) {
          const iOrden = oVue.getOrdenIndex(aOrden.col.index);
          const aHeader = headerExcel[iOrden];
          sHeader = aHeader.text;
        } else {
          sHeader = aOrden.dynamic.header;
        }
        aExcelHeader.push(sHeader);
      });

      let ws_data = [aExcelHeader];
      oVue.bodyExcel.forEach((aRow) => {
        let aRowTmp = [];
        aOrden.forEach((aOrden) => {
          let sValue = "";
          if (aOrden.isCol) {
            const iOrden = oVue.getOrdenIndex(aOrden.col.index);
            const bImagen = aOrden.col.imagen;
            const aHeader = headerExcel[iOrden];
            sValue = aRow[aHeader.value];
            sValue = sValue === "undefined" ? "" : sValue;
            if (bImagen) {
              if (sValue !== "") {
                sValue =
                  "https://pub.its-job.com/media/" +
                  aRow.campanaID +
                  "/" +
                  sValue;
              }
            }
          } else {
            sValue = aOrden.dynamic.value(oVue, aRow);
          }
          aRowTmp.push(sValue);
        });

        ws_data.push(aRowTmp);
      });
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      return ws;
    },
    getOrdenIndex(index) {
      return index - 1;
    },
    isISODateValid(date) {
      date = date.toString().replaceAll(".000Z", "");
      date = date.replaceAll(".999Z", "");
      date = date.replaceAll("T", " ");
      return moment(date, "YYYY-MM-DD HH:mm:ss", true).isValid();
    },
    filterTasks() {
      this.showTasks();
      const oVue = this;
      let tasksFiltered = oVue.tasks.tasks;
      oVue.filters.forEach((f) => {
        if (f.input !== "") {
          tasksFiltered = tasksFiltered.filter((t) => {
            if (t[f.value]) {
              return t[f.value]
                .toString()
                .toLowerCase()
                .includes(f.input.toLowerCase());
            } else {
              return false;
            }
          });
        }
      });
      if (oVue.fechaAsigna.length > 0) {
        const startDate =
          oVue.fechaAsigna[0] !== ""
            ? moment(`${oVue.fechaAsigna[0]} 00:00:00`, "YYYY-MM-DD HH:mm:ss")
            : moment(`${oVue.fechaAsigna[1]} 00:00:00`, "YYYY-MM-DD HH:mm:ss");
        const endDate = moment(
          `${
            typeof oVue.fechaAsigna[1] !== "undefined"
              ? oVue.fechaAsigna[1]
              : oVue.fechaAsigna[0]
          } 23:59:59`,
          "YYYY-MM-DD HH:mm:ss"
        );
        tasksFiltered = tasksFiltered.filter((t) => {
          if (!t.fechaAsignacion) return false;
          const fechaAsignacion = moment(
            `${t.fechaAsignacion} 00:00:00`,
            "DD-MM-YYYY HH:mm:ss"
          );
          return fechaAsignacion.isBetween(startDate, endDate, null, "[]");
        });
      }
      if (oVue.fechaRealiza.length > 0) {
        const startDate =
          oVue.fechaRealiza[0] !== ""
            ? moment(`${oVue.fechaRealiza[0]} 00:00:00`, "YYYY-MM-DD HH:mm:ss")
            : moment(`${oVue.fechaRealiza[1]} 00:00:00`, "YYYY-MM-DD HH:mm:ss");
        const endDate = moment(
          `${
            typeof oVue.fechaRealiza[1] !== "undefined"
              ? oVue.fechaRealiza[1]
              : oVue.fechaRealiza[0]
          } 23:59:59`,
          "YYYY-MM-DD HH:mm:ss"
        );
        tasksFiltered = tasksFiltered.filter((t) => {
          if (!t.fechaGestion) return false;
          const fechaRealizacion = moment(
            `${t.fechaGestion} 00:00:00`,
            "DD-MM-YYYY HH:mm:ss"
          );
          return fechaRealizacion.isBetween(startDate, endDate, null, "[]");
        });
      }
      oVue.tasksFiltered = tasksFiltered;
    },
    editGroup(aGroup) {
      const oVue = this;
      oVue.group = aGroup;
      oVue.getCampaigns();
      oVue.editGroupDialog = true;
    },
    addCampaignGroup() {
      const oVue = this;
      const aCampaign = oVue.campaign;

      let aData = {
        group: oVue.group.uniqueID,
        campaign: aCampaign.campaignID,
      };
      oVue.newCampaignGroup(aData).then((aNewCampaignGroupResponse) => {
        KTUtil.removeLoading();
        if (aNewCampaignGroupResponse.result) {
          Swal.fire({
            title: "¡Enhorabuena!",
            text: "La campaña ha sido agregada exitosamente",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
          oVue.campaigns = oVue.campaigns.filter(
            (c) => c.campaignID !== aCampaign.campaignID
          );
          oVue.group.campaigns = [...oVue.group.campaigns, aCampaign];
          oVue.campaign = null;
        } else {
          Swal.fire({
            title: "Error al guardar la campaña",
            text: aNewCampaignGroupResponse.message,
            icon: "error",
          });
        }
      });
    },
    deleteCampaignGroup(aCampaign) {
      const oVue = this;
      let aData = {
        group: oVue.group.uniqueID,
        campaign: aCampaign.campaignID,
      };
      oVue.removeCampaignGroup(aData).then((aRemoveCampaignGroupResponse) => {
        KTUtil.removeLoading();
        if (aRemoveCampaignGroupResponse.result) {
          oVue.group.campaigns = oVue.group.campaigns.filter(
            (c) => c.campaignID !== aCampaign.campaignID
          );
          if (oVue.isActiveCampaign(aCampaign)) {
            oVue.campaignsSelect.push(aCampaign);
          }
        } else {
          Swal.fire({
            title: "Error al eliminar la campaña",
            text: aRemoveCampaignGroupResponse.message,
            icon: "error",
          });
        }
      });
    },
    async updateGroup() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.groups = [];
      const updateGroupResponse = await oVue.$store.dispatch(UPDATE_GROUP, {
        id: Number(oVue.group.uniqueID),
        nombre: oVue.group.nombre,
        descripcion: oVue.group.descripcion,
      });
      KTUtil.removeLoading();
      return updateGroupResponse;
    },
    async newCampaignGroup(aData) {
      KTUtil.setLoading();
      let oVue = this;
      const aNewCampaignResponse = await oVue.$store.dispatch(
        ADD_CAMPAIGN_GROUP,
        aData
      );
      return aNewCampaignResponse;
    },
    async removeCampaignGroup(aData) {
      let oVue = this;
      const aRemoveCampaignResponse = await oVue.$store.dispatch(
        DELETE_CAMPAIGN_GROUP,
        aData
      );
      return aRemoveCampaignResponse;
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.showTasks();
    },
    handlePageChange(value) {
      this.page = value;
      this.showTasks();
    },
    sortTable() {
      this.showTasks();
    },
    clearFilter(field) {
      const oVue = this;
      oVue[field] = [];
      oVue.showTasks();
    },
    isActiveCampaign(c) {
      const UNAVAILABLE_CAMPAIGN_STATUS = [1, 2, 7];
      return !UNAVAILABLE_CAMPAIGN_STATUS.includes(c.estadoID);
    },
  },
  mounted() {
    const oVue = this;
    oVue.$store.dispatch(SET_BREADCRUMB, [
      { title: "Grupos", route: "campaigns/groups" },
    ]);

    oVue.getMandantes();

    oVue.mandanteID = oVue.currentUserPersonalInfo.mandanteID;
    const bIsMandante = oVue.mandanteID !== "null";
    if (bIsMandante) {
      oVue.getGroups();
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    fechaAsignaText() {
      let oVue = this;
      const aDates = oVue.fechaAsigna;

      let sFechaIni = "";
      let sFechaFin = "";
      if (typeof aDates[0] !== "undefined" && aDates[0] !== "") {
        sFechaIni = moment(aDates[0], "YYYY/MM/DD").format("DD-MM-YYYY");
      }
      if (typeof aDates[1] !== "undefined") {
        sFechaFin = moment(aDates[1], "YYYY/MM/DD").format("DD-MM-YYYY");
      }
      let fechaAsignaText = "";
      if (sFechaIni || sFechaFin) {
        if (sFechaIni && sFechaFin) {
          fechaAsignaText = sFechaIni + " ~ " + sFechaFin;
        } else {
          if (sFechaIni) {
            fechaAsignaText = sFechaIni;
          } else {
            fechaAsignaText = sFechaFin;
          }
        }
      } else {
        fechaAsignaText = "";
      }
      oVue.modalFechaAsignaTitle = `Fecha de Asignación: ${fechaAsignaText}`;
      return fechaAsignaText;
    },
    fechaRealizaText() {
      let oVue = this;
      const aDates = oVue.fechaRealiza;

      let sFechaIni = "";
      let sFechaFin = "";
      if (typeof aDates[0] !== "undefined" && aDates[0] !== "") {
        sFechaIni = moment(aDates[0], "YYYY/MM/DD").format("DD-MM-YYYY");
      }
      if (typeof aDates[1] !== "undefined") {
        sFechaFin = moment(aDates[1], "YYYY/MM/DD").format("DD-MM-YYYY");
      }
      let fechaRealizaText = "";
      if (sFechaIni || sFechaFin) {
        if (sFechaIni && sFechaFin) {
          fechaRealizaText = sFechaIni + " ~ " + sFechaFin;
        } else {
          if (sFechaIni) {
            fechaRealizaText = sFechaIni;
          } else {
            fechaRealizaText = sFechaFin;
          }
        }
      } else {
        fechaRealizaText = "";
      }
      oVue.modalFechaRealizaTitle = `Fecha de Realización: ${fechaRealizaText}`;
      return fechaRealizaText;
    },
  },
  watch: {
    taskSearch: function () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.showTasks();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
};
</script>
