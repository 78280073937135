<template>
  <div>
    <v-autocomplete
      v-model="location"
      :items="searchResults"
      :search-input.sync="locationSearchText"
      item-key="place_id"
      item-text="description"
      hide-no-data
      return-object
      label="Dirección"
      autocomplete="new-password"
      dense
    ></v-autocomplete>
  </div>
</template>
<script>
import { appendGoogleMapScript } from "../../../helpers/helper";
export default {
  name: "Place",
  data: () => ({
    location: "",
    searchResults: [],
    autocompleteService: null,
    placeService: null,
    locationSearchText: null,
    map: document.createElement("div"),
    init: true,
  }),
  mounted() {
    this.loadGooglePlaces();
  },
  methods: {
    setDireccion(oDireccion) {
      this.searchResults = oDireccion;
      this.location = oDireccion[0];
      this.init = false;
    },
    async loadGooglePlaces() {
      let oVue = this;
      if (typeof google !== "object" || typeof google.maps !== "object") { // eslint-disable-line
        await appendGoogleMapScript();
      }
      oVue.MapsInit();
    },
    MapsInit() {
      this.autocompleteService =
        new window.google.maps.places.AutocompleteService();
      // eslint-disable-next-line
      this.placeService = new google.maps.places.PlacesService(this.map);
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = [];
        return;
      }
      this.searchResults = predictions;
    },
  },
  watch: {
    locationSearchText(newValue) {
      if (newValue) {
        this.autocompleteService.getPlacePredictions(
          {
            input: this.locationSearchText,
            types: ["geocode"],
            sensor: true,
            country: "CL",
            language: "es",
            componentRestrictions: {
              country: "CL",
            },
          },
          this.displaySuggestions
        );
      }
    },
    location() {
      let oVue = this;
      if (!oVue.init) {
        oVue.init = true;
        return;
      }
      this.placeService.getDetails(
        {
          placeId: oVue.location.place_id,
          fields: [
            "address_components",
            "formatted_address",
            "geometry.location",
          ],
        },
        // eslint-disable-next-line
        (PlaceResult, PlacesServiceStatus) => {
          oVue.$emit("updateFields", PlaceResult);
        }
      );
    },
  },
};
</script>
