<template>
  <div>
    <v-btn
      v-if="sFilename && sFilename !== ''"
      color="primary"
      dark
      class="mb-2"
      v-on:click="showFile()"
    >
      Ver Archivo
    </v-btn>
    <v-dialog
      v-model="dialogFile"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" style="flex: none">
          <v-btn
            icon
            dark
            @click="
              dialogFile = false;
              fileBase64 = null;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>ARCHIVO</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pt-2 bg-default text-center">
          <img :src="fileBase64" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GET_FILE } from "@/core/services/store/task.module";
import KTUtil from "@/assets/js/components/util";
export default {
  name: "TaskImage",
  props: {
    iCampaignID: Number,
    sFilename: String,
  },
  data() {
    return {
      dialogFile: false,
      fileBase64: null,
    };
  },
  methods: {
    async showFile() {
      let oVue = this;
      const aFileResponse = await oVue.getFile();
      if (aFileResponse.result) {
        oVue.fileBase64 = "data:image/png;base64," + aFileResponse.data;
        oVue.dialogFile = true;
      }
    },
    async getFile() {
      KTUtil.setLoading();
      let oVue = this;
      const aFileReponse = await oVue.$store.dispatch(GET_FILE, {
        campaign: oVue.iCampaignID,
        fileName: oVue.sFilename,
      });
      KTUtil.removeLoading();

      return aFileReponse;
    },
  },
};
</script>
