<template>
  <div>
    <v-row>
      <v-col cols="12" v-if="bCircle">
        <v-app style="height: 25px">
          <v-slider
            v-model="gMapCircleRadius"
            label="Radio (km)"
            thumb-color="red"
            thumb-label="always"
            max="100"
            min="1"
            v-on:change="changeRangeGMapRadius()"
          ></v-slider>
        </v-app>
      </v-col>
      <v-col cols="12" v-if="bCircle">
        <Place ref="map_place" @updateFields="updateGMapCirclePosition"></Place>
      </v-col>
      <v-col cols="12">
        <div :id="map_id" style="height: 500px"></div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Place from "../common/Places.vue";
import { appendGoogleMapScript } from "../../../helpers/helper";
export default {
  name: "TaskMap",
  components: {
    Place,
  },
  props: {
    map: String,
    aJobs: Array,
    bCircle: Boolean,
  },
  data() {
    return {
      gMap: null,
      gMapCircle: null,
      gMapCircleRadius: 50,
      gMapMarkers: [],
      gMapCirclePosition: {
        latitud: -33.448376330976956,
        longitud: -70.6702090566707,
      },
      map_id: null,
      idEstadoTipos: ["idEstado", "id_estado", "statusID"],
    };
  },
  computed: {
    google: () => window.google,
  },
  async created() {
    if (
      !this.bCircle &&
      (typeof this.google !== "object" || typeof this.google.maps !== "object")
    ) {
      await appendGoogleMapScript();
    }
    this.map_id = this.map ?? "google_map";
  },
  methods: {
    initJobsMap() {
      setTimeout(() => {
        let oVue = this;
        let iZoom;
        if (oVue.aJobs.length > 0) {
          const aJob = oVue.aJobs.find((aJob) =>
            oVue.getPosition(aJob.latitud, aJob.longitud)
          );
          if (aJob) oVue.gMapCirclePosition = aJob;
          if (oVue.aJobs.length > 2) {
            iZoom = 10;
          } else {
            iZoom = 15;
          }
        } else {
          iZoom = 4;
        }
        const oPosition = oVue.gMapCirclePosition;
        if (
          oPosition.latitud === "undefined" ||
          oPosition.longitud === "undefined"
        ) {
          return;
        }

        const latlng = oVue.getPosition(oPosition.latitud, oPosition.longitud);

        const mapOptions = {
          center: latlng,
          zoom: iZoom,
          mapTypeId: this.google.maps.MapTypeId.ROADMAP,
        };

        const el = document.getElementById(oVue.map_id);

        oVue.gMap = new this.google.maps.Map(el, mapOptions);

        if (oVue.bCircle) {
          const iKilometros = oVue.gMapCircleRadius;
          const iMetros = iKilometros * 1000;

          oVue.gMapCircle = new this.google.maps.Circle({
            strokeColor: "#c3fc49",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#c3fc49",
            fillOpacity: 0.35,
            map: oVue.gMap,
            center: latlng,
            radius: iMetros,
          });
        }
        oVue.renderJobsMarkers();
      }, 1000);
    },
    renderJobsMarkers() {
      let oVue = this;
      for (let i = 0; i <= oVue.gMapMarkers.length - 1; i++) {
        oVue.gMapMarkers[i].setMap(null);
      }
      oVue.gMapMarkers = [];
      if (oVue.aJobs.length == 0) {
        return;
      }
      const aFirstJob = oVue.aJobs[0];
      const idEstado = oVue.idEstadoTipos.find((type) =>
        aFirstJob.hasOwnProperty(type)
      );
      for (const aJob of oVue.aJobs) {
        if (
          typeof aJob.latitud === "undefined" ||
          typeof aJob.longitud === "undefined"
        ) {
          continue;
        }
        if (oVue.bCircle) {
          const aPosition = oVue.getPosition(aJob.latitud, aJob.longitud);
          if (!aPosition) continue;
          if (
            this.google.maps.geometry.spherical.computeDistanceBetween(
              aPosition,
              oVue.gMapCircle.getCenter()
            ) <= oVue.gMapCircle.getRadius()
          ) {
            oVue.addJob(aJob, idEstado);
          }
        } else {
          oVue.addJob(aJob, idEstado);
        }
      }
    },
    addJob(aJob, idEstado) {
      let oVue = this;
      const sMarker = aJob.type === "jobber" ? "green" : "blue";
      const position = oVue.getPosition(aJob.latitud, aJob.longitud);
      if (!position) return;

      const aMarker = new this.google.maps.Marker({
        position,
        map: oVue.gMap,
        icon: aJob[idEstado] <= 2 ? "" : `/media/misc/${sMarker}-marker.png`,
      });
      oVue.gMapMarkers.push(aMarker);
    },
    changeRangeGMapRadius() {
      let oVue = this;

      const iKilometros = oVue.gMapCircleRadius;
      const iMetros = iKilometros * 1000;

      oVue.gMapCircle.setMap(null);

      const latlng = oVue.getPosition(
        oVue.gMapCirclePosition.latitud,
        oVue.gMapCirclePosition.longitud
      );

      oVue.gMapCircle = new this.google.maps.Circle({
        strokeColor: "#c3fc49",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#c3fc49",
        fillOpacity: 0.35,
        map: oVue.gMap,
        center: latlng,
        radius: iMetros, // in meters
      });

      oVue.gMap.setCenter(latlng);

      oVue.renderJobsMarkers();
    },
    updateGMapCirclePosition(oData) {
      let oVue = this;

      const oLocation = oData.geometry.location;

      oVue.gMapCirclePosition.latitud = oLocation.lat();
      oVue.gMapCirclePosition.longitud = oLocation.lng();

      oVue.changeRangeGMapRadius();
    },
    getPosition(latitud, longitud) {
      if (!latitud || !longitud) return false;
      latitud = +latitud.toString().replace(",", ".");
      longitud = +longitud.toString().replace(",", ".");
      if (latitud === 0 || longitud === 0) return false;
      return new this.google.maps.LatLng(latitud, longitud);
    },
  },
  watch: {
    aJobs: function () {
      if (this.aJobs.length == 0) return;
      this.initJobsMap();
    },
  },
};
</script>
