<template>
  <v-btn color="primary" dark class="mb-2" v-on:click="getCertificate()">
    CERTIFICADO
  </v-btn>
</template>

<script>
import {
  GET_FILE,
  GENERATE_CERTIFICATE,
} from "@/core/services/store/task.module";
import KTUtil from "@/assets/js/components/util";
import { _ } from "vue-underscore";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { GET_CAMPAIGN_CERTIFICATE } from "@/core/services/store/campaign.module";
export default {
  name: "TaskCertificate",
  props: {
    taskData: Object,
    oMap: HTMLDivElement,
  },
  data() {
    return {
      aCertificado: null,
      aPreguntas: [],
    };
  },
  methods: {
    async getCampaignCertificate() {
      KTUtil.setLoading();
      let oVue = this;
      const aCertificado = await oVue.$store.dispatch(
        GET_CAMPAIGN_CERTIFICATE,
        oVue.taskData.campaignID
      );
      if (aCertificado.result) {
        oVue.aCertificado = aCertificado.data;
      } else {
        oVue.aCertificado = null;
      }

      KTUtil.removeLoading();
    },
    async getCertificate() {
      const oVue = this;
      await oVue.construirArbol();
      await oVue.getCampaignCertificate();
      if (oVue.aCertificado !== null) {
        const taskData = oVue.taskData;
        const iTaskID = taskData.taskID;

        const aCustomCertificate = await oVue.generateCustomCertificate(
          iTaskID
        );
        if (aCustomCertificate.result) {
          window.open(aCustomCertificate.data, "_blank");
        }
      } else {
        this.printTask();
      }
    },
    async printTask() {
      let oVue = this;
      let nPageYAxi = 10;
      const iMargin = 9;
      const iFontText = 11;
      const iMarginHeader = 28;
      //funcion se emplea para centrar en texto en funcion del ancho del la hora configurada
      const centeredText = function (sText, y) {
        const textWidth =
          (oDocPdf.getStringUnitWidth(sText) * oDocPdf.internal.getFontSize()) /
          oDocPdf.internal.scaleFactor;
        const textOffset = (oDocPdf.internal.pageSize.width - textWidth) / 2;
        oDocPdf.text(textOffset, y, sText);
      };
      //slitText se emplea para dividir el texto de un parrafo en multiples lineas, en funcion del ancho enviado en el parametro iMaxWidth
      //el cual es el ancho maximo permitido para el texto.
      //iIncreaseY = es alto que se aumentara por cada linea
      //de ser necesario, el valor del alto de la ultima linea se guarda en la variable nPageYAxi
      //iXAxis = posicion en eje X para inicio del texto.
      const slitText = function (
        sText,
        iMaxWidth,
        iIncreaseY,
        iXAxis,
        isCenter
      ) {
        const aTextLines = oDocPdf.splitTextToSize(sText, iMaxWidth);
        let iIncreaseY2 = 0;
        if (Array.isArray(iIncreaseY)) {
          iIncreaseY2 = iIncreaseY[1];
          iIncreaseY = iIncreaseY[0];
        } else {
          iIncreaseY2 = iIncreaseY;
        }
        aTextLines.forEach((sLine, i) => {
          if (i != 0) {
            increasePageY(iIncreaseY);
          } else {
            increasePageY(iIncreaseY2);
          }
          if (isCenter) {
            centeredText(sLine, nPageYAxi); //nPageYAxi posee el valor del ultimo alto ingresado
          } else {
            oDocPdf.text(iXAxis, nPageYAxi, sLine);
          }
        });
      };
      //Se emplea para aumentar el contador
      const increasePageY = function (iIncreaseValue) {
        nPageYAxi += iIncreaseValue;
        if (nPageYAxi > oDocPdf.internal.pageSize.height - iMargin - 20) {
          addPage();
          nPageYAxi = iMarginHeader + 8;
        }
      };
      const identText = (sTitle, sValue, iMaxWidth, iMaxWidthValue = null) => {
        if (!iMaxWidthValue) {
          if (iMaxWidth > 50) {
            iMaxWidthValue = 100;
          } else {
            iMaxWidthValue = iMaxWidth;
          }
        } else {
          const iMaxLength = 50;
          sTitle = getMaxText(sTitle, iMaxLength);
        }
        slitText(sTitle, iMaxWidth, 8, iMargin, false);
        slitText(sValue, iMaxWidthValue, [8, 0], iMaxWidth, false);
      };

      const getMaxText = (sText, iMaxLength) => {
        if (sText.length > iMaxLength) {
          sText = sText.substr(0, iMaxLength) + "...";
        }
        return sText;
      };

      const addPage = () => {
        let sTitle = "";
        switch (iSection) {
          case 2:
            sTitle = "Datos de la campaña";
            break;
          case 3:
            sTitle = "Datos de la tarea";
            break;
          case 4:
            sTitle = "Árbol de gestión";
            break;
          case 5:
            sTitle = "Evidencia Objetiva";
        }
        oDocPdf.addPage("letter", "portrait");
        oDocPdf.addImage(oLogo, "PNG", 10, 5, 15, 10);
        oDocPdf.setFontSize(20);
        oDocPdf.setTextColor(21, 115, 186); //blue job
        oDocPdf.setFont("Helvetica", "normal", 400);
        oDocPdf.text(sTitle, iMargin, 22);
        oDocPdf.setDrawColor(247, 148, 29); // orange job
        oDocPdf.setLineWidth(0.5);
        oDocPdf.line(10, 26, 205, 26);
        oDocPdf.setTextColor(56, 56, 56); // gray text
        oDocPdf.setFontSize(iFontText);
        oDocPdf.setFont("Helvetica", "normal", 400);
        //al iniciar nueva pagina y agregar el header establesco el alto inicial
        nPageYAxi = iMarginHeader;
      };

      const addFooter = () => {
        const pages = oDocPdf.internal.getNumberOfPages();
        const pageWidth = oDocPdf.internal.pageSize.width; //Optional
        const pageHeight = oDocPdf.internal.pageSize.height; //Optional
        oDocPdf.setFontSize(10); //Optional

        for (let j = 1; j < pages + 1; j++) {
          let horizontalPos = pageWidth / 2; //Can be fixed number
          let verticalPos = pageHeight - 10; //Can be fixed number
          oDocPdf.setPage(j);
          oDocPdf.setDrawColor(247, 148, 29); // orange job
          oDocPdf.setLineWidth(0.5);
          oDocPdf.line(10, 255, 205, 255);
          oDocPdf.text("Fecha de emisión", iMargin, 264);
          oDocPdf.text(new Date(Date.now()).toLocaleString(), iMargin, 269);
          oDocPdf.text(`Página ${j} de ${pages}`, horizontalPos, verticalPos, {
            align: "center",
          });
        }
      };

      //@todo - print fuction
      //dimesiones de carta
      //215.9 x 279.4
      const oDocPdf = new jsPDF({ format: "letter", unit: "mm" });
      const iMaxPageWidth = 215.9 - iMargin * 2;
      //************** */
      //PRIMERA PAGINA
      //************** */
      let iSection = 1;
      let oLogo = new Image();
      oLogo.src = "/media/logos/logo-letter-1.png";
      oDocPdf.addImage(oLogo, "PNG", 10, 5, 20, 15);
      oDocPdf.setFontSize(iFontText);
      oDocPdf.setTextColor(56, 56, 56); // gray text
      centeredText("Morandé 835 oficina 1614 Santiago", 10);
      centeredText("Teléfono: +56 229 939 749", 15);
      centeredText("email: contacto@itsjob.cl", 20);
      centeredText("www.itsjob.cl", 25);
      oDocPdf.setFontSize(25);
      oDocPdf.setTextColor(21, 115, 186); //blue job
      oDocPdf.setFont("Helvetica", "normal", 400);
      centeredText(oVue.taskData.campaignType, 100);
      oDocPdf.setFontSize(iFontText);
      oDocPdf.setTextColor(56, 56, 56); //blue job
      oDocPdf.setFont("Helvetica", "normal", 400);
      nPageYAxi = 100;
      slitText(
        "Campaña: (" +
          oVue.taskData.campaignID +
          ") " +
          oVue.taskData.campaignName,
        120,
        8,
        iMargin,
        true
      );
      slitText("Tarea: " + oVue.taskData.taskID, 120, 8, iMargin, true);
      slitText(
        "Mandante: " + oVue.taskData.mandanteNombre,
        120,
        8,
        iMargin,
        true
      );

      //************* */
      //SEGUNDA PAGINA
      //************* */
      iSection = 2;
      addPage();
      const iLengthTitleJobData = 48.975;
      const iLengthValueJobData = 80;
      identText(
        "Campaña: ",
        "(" + oVue.taskData.campaignID + ")" + oVue.taskData.campaignName,
        iLengthTitleJobData,
        iLengthValueJobData
      );
      identText(
        "Mandante: ",
        oVue.taskData.mandanteNombre,
        iLengthTitleJobData,
        iLengthValueJobData
      );
      identText(
        "Descripción: ",
        oVue.taskData.campaignDescription,
        iLengthTitleJobData,
        iLengthValueJobData
      );
      identText(
        "Tipo: ",
        oVue.taskData.campaignType,
        iLengthTitleJobData,
        iLengthValueJobData
      );
      identText(
        "Estado: ",
        oVue.taskData.campaignStatus,
        iLengthTitleJobData,
        iLengthValueJobData
      );
      identText(
        "Fecha Inicio: ",
        oVue.taskData.initDate,
        iLengthTitleJobData,
        iLengthValueJobData
      );
      identText(
        "Fecha Termino: ",
        oVue.taskData.finalDate,
        iLengthTitleJobData,
        iLengthValueJobData
      );

      nPageYAxi = nPageYAxi + 10;

      //mapa
      const oImgMap = await html2canvas(oVue.oMap, {
        useCORS: true,
        allowTaint: true,
        // scrollX: 0,
        scrollY: 0,
        height: 500,
        width: 500,
        scale: 1,
      });
      const oImgData = oImgMap.toDataURL("image/png");
      oDocPdf.addImage(oImgData, "png", iMargin, nPageYAxi, iMaxPageWidth, 150);
      // El 97.95, es por que solo quiero que cresca hasta la mitad de la pantalla, ya que en la otra mitad esta el mapa
      iSection = 3;
      addPage();
      //asi quedaria para generar varios registros en base a los arreglos de forma rapida
      identText("Jobber: ", oVue.taskData.jobberName, 70);
      identText("Fecha Asigna: ", oVue.taskData.assignmentDate, 70);
      identText("Fecha Gestion: ", oVue.taskData.managmentDate, 70);
      identText("Estado: ", oVue.taskData.estado, 70);
      for (let sDatos of oVue.taskData.jobsFile) {
        //jobsFile
        identText(sDatos.name.trim() + ": ", sDatos.value, 70);
      }

      //************* */
      //TERCERA PAGINA
      //************* */
      iSection = 4;
      addPage();

      oDocPdf.setFont("Helvetica", "normal", 400);
      oVue.aPreguntas.forEach((oPregunta) => {
        identText(oPregunta.title, oPregunta.value, 110, 100);
      });

      //Evidencias Objetivas
      const aFieldsImg = _.filter(oVue.taskData.jobsConfig, { tipoID: 10 });

      if (aFieldsImg.length > 0) {
        iSection = 5;
        addPage();

        for (let i = 0; i <= aFieldsImg.length - 1; i++) {
          const aField = aFieldsImg[i];
          const sTitle = aField.name;
          const sFilename = aField.value;
          const aFileResponse = await oVue.getFile(
            oVue.taskData.campaignID,
            sFilename
          );

          if (aFileResponse.result) {
            const sBase64 = "data:image/png;base64," + aFileResponse.data;
            let oImg = new Image();
            oImg.src = sBase64;
            slitText(sTitle, iMaxPageWidth, 8, iMargin, true);

            nPageYAxi = nPageYAxi + 2;

            oDocPdf.addImage(
              oImg,
              "PNG",
              iMargin,
              nPageYAxi,
              iMaxPageWidth,
              100
            );
            increasePageY(100);
          }
        }
      }
      addFooter();
      oDocPdf.save("itsjob_tarea_" + oVue.taskData.taskID.toString() + ".pdf");
    },
    async getFile(iCampaignID, sFilename) {
      KTUtil.setLoading();
      let oVue = this;
      const aFileReponse = await oVue.$store.dispatch(GET_FILE, {
        campaign: iCampaignID,
        fileName: sFilename,
      });
      KTUtil.removeLoading();

      return aFileReponse;
    },
    async construirArbol() {
      let oVue = this;
      let aPreguntas = [];
      let iPregunta = 0;
      let sRespuesta = "";
      oVue.taskData.treeRows.forEach((sDatos, i) => {
        //treeRows
        if (sDatos.ordenCampo) {
          iPregunta++;
          if (!sDatos.tieneOpciones) {
            if (sDatos.tipoCampo != "Imagen") {
              sRespuesta = sDatos.seleccionado ? sDatos.respuesta : "";
            } else {
              sRespuesta = sDatos.seleccionado ? sDatos.respuesta : "NO";
            }
          } else {
            sRespuesta = "";
            for (const [iOpcionIndex, aOpcion] of oVue.taskData.treeRows
              .slice(i, oVue.taskData.treeRows.length)
              .entries()) {
              if (iOpcionIndex != 0 && aOpcion.ordenCampo) {
                break;
              }
              if (aOpcion.seleccionado) {
                sRespuesta = aOpcion.descripcion;
                break;
              }
            }
          }
          if (sRespuesta) {
            aPreguntas.push({
              title: `${iPregunta} ${sDatos.pregunta}`,
              value: sRespuesta,
            });
          }
        }
      });
      oVue.aPreguntas = aPreguntas;
    },
    async generateCustomCertificate(iTaskID) {
      KTUtil.setLoading();
      let oVue = this;
      const aGeneratedCertificate = await oVue.$store.dispatch(
        GENERATE_CERTIFICATE,
        { task: iTaskID }
      );
      KTUtil.removeLoading();

      return aGeneratedCertificate;
    },
  },
};
</script>
