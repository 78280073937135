export const aOrden = [
  {
    isCol: true,
    col: {
      index: 1,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 2,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 3,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 4,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 5,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 6,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 7,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 8,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 9,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 10,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 11,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 12,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 13,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 14,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 15,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 16,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 17,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 18,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 19,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 20,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 21,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 22,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 23,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 24,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 25,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 26,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 27,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 29,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 30,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 31,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 32,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 35,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 36,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 38,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 40,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 41,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: false,
    col: {
      index: null,
      imagen: true,
    },
    dynamic: {
      header: "Resultado de la Visita",
      value: function (oVue, aRow) {
        return aRow.ResultadoVisita;
      },
    },
  },
  {
    isCol: true,
    col: {
      index: 44,
      imagen: false,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 28,
      imagen: true,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 34,
      imagen: true,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 37,
      imagen: true,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: true,
    col: {
      index: 39,
      imagen: true,
    },
    dynamic: {
      header: "",
      value: null,
    },
  },
  {
    isCol: false,
    col: {
      index: null,
      imagen: true,
    },
    dynamic: {
      header: "MAPA",
      value: function (oVue, aRow) {
        let sToReturn =
          "https://www.google.com/maps/search/" +
          aRow.latitud +
          ",+" +
          aRow.longitud;

        return sToReturn;
      },
    },
  },
  {
    isCol: false,
    col: {
      index: null,
      imagen: true,
    },
    dynamic: {
      header: "CERTIFICADO",
      value: function (oVue, aRow) {
        let sToReturn = aRow.urlCertificado;

        return sToReturn;
      },
    },
  },
];
